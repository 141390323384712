@tailwind base;
@tailwind components;
@tailwind utilities;

.no-ring {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none;
}

* {
  appearance: none;
}

select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='lightgray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

/* Customize scrollbar on firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: #18191c #36393e;
}

/* Customize scrollbar for all webkit browsers */

/* total width */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 14px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #18191c;
  border-radius: 16px;
  border: 3px solid #36393e;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
